@tailwind base;
@tailwind components;
@tailwind utilities;

/* Raleway font added across the app */
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
  margin: 0;
  font-family: Mukta, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #060914;
}

@font-face {
  font-family: 'password';
  font-style: normal;
  font-weight: 400;
  src: url(https://jsbin-user-assets.s3.amazonaws.com/rafaelcastrocouto/password.ttf);
}

input.key {
  font-family: 'password';
}

.site_dropdown .searchWrapper {
  border-radius: 0;
  min-height: 42px;
  overflow: hidden;
  border: none;
}
.site_dropdown > div {
  width: 60%;
}
.site_dropdown button {
  width: 38%;
}
.site_dropdown input {
  width: 100%;
}
.site_dropdown .singleChip {
  max-width: calc(100% - 15px);
  overflow: hidden;
}

.remove_btn .loader {
  display: none;
}
.remove_btn.loading .loader {
  display: flex;
}

@media only screen and (max-width: 640px) {
  .site_dropdown > div, .site_dropdown button {
    width: 100%;
  }
}
.removeScroll::-webkit-scrollbar {
  width: 0px;
}

@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translateY(-6px);
  }
}

.bouncing-loader {
  display: flex;
  justify-content: center;
  margin-top: 4rem;
}

.bouncing-loader > div {
  width: 6px;
  height: 6px;
  margin: 3px 4px;
  border-radius: 50%;
  background-color: #000000;
  opacity: 1;
  animation: bouncing-loader 0.6s infinite alternate;
}

.bouncing-loader > div:nth-child(2) {
  animation-delay: 0.2s;
}

.bouncing-loader > div:nth-child(3) {
  animation-delay: 0.4s;
}

.tooltip {
  position: relative;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: max-content;
  background-color: rgba(0, 0, 0, 0.85)  ;
  color: #fff;
  border-radius: 6px;
  padding: 5px 10px;
  position: absolute;
  z-index: 1;
  top: -10px;
  right: calc(100% + 5px);
  opacity: 0;
  transition: opacity 0.5s;
}
.tooltip .tooltiptext.right {
  right: 0;
  left: calc(100% + 5px);
}
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent rgba(0, 0, 0, 0.85);
}

.tooltip .tooltiptext.right::after {
  left: -10px;
  transform: rotate(180deg);
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.info {
  width: 26px;
  height: 26px;
  transition: padding 0.5s ease-in-out;
}

.info:hover {
  width: 100%;
  height: auto;
  padding: 10px;
}

.MuiPagination-root .MuiPaginationItem-root {
  margin: 0;
  border-radius: 0;
  border: 1px solid #000;
  border-top: 0;
  border-bottom: 0;
  height: 100%;
  min-width: 40px;
  min-height: 40px;
}
.MuiPagination-root .MuiPaginationItem-previousNext, 
.MuiPagination-root .MuiPaginationItem-root.Mui-selected {
  background-color: #000000;
  border-color: #000000;
  border-top: 0;
  border-bottom: 0;
  color: #fff;
}
.MuiPagination-root .MuiPaginationItem-previousNext:hover, 
.MuiPagination-root .MuiPaginationItem-root.Mui-selected:hover {
  background-color: #000000;
  border-color: #000000;
  color: #fff;
}
.MuiPagination-root .MuiPaginationItem-previousNext.Mui-disabled {
  background-color: #E5E5E5;
  /* border-color: #E5E5E5; */
  color: #C7CCD5;
  opacity: 1;
}

.sidebar_element svg {
  margin: auto;
}
@media only screen and (max-width: 640px) {
  select hr {
    display: none;
  }
}
.react-tel-input.phoneNum {
  display: flex;
  flex-direction: row-reverse;
}
.react-tel-input .form-control.phoneInput {
  font-family: sans-serif;
  border: 1px solid transparent;
  border-bottom: 2px solid #000000;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  outline: none;
  width: 100%;
  border-radius: 0;
  padding: 0.5rem 0.75rem;
}
.errorField .PhoneInputInput {
  border-color: red;
}
.react-tel-input .flag-dropdown.countryDropdown {
  position: relative;
  left: 1px;
  border-bottom: 2px solid #000000;
  background-color: white;
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0px;
}
.axScore {
  position: absolute;
  z-index: 2;
}
.axScore-innertext {
  display: none;
}
.axScore-arcs-path {
  opacity: 1;
}
.levelA .bar_bg {
  background-color: #FF5353;
}
.levelAA .bar_bg {
  background-color: #FC8763;
}

.levelAAA .bar_bg {
  background-color: #DADADA;
}
.table-scroll tbody::-webkit-scrollbar {
  display: none;
}
.table-scroll th:nth-child(1) > div {
  justify-content: flex-end;
}

.table-scroll td:nth-child(2),
.table-scroll th:nth-child(2){
  flex-basis:70%;
}

.table-scroll td:nth-child(3),
.table-scroll th:nth-child(3){
  flex-basis:45%;
}

.table-scroll td:nth-child(4),
.table-scroll th:nth-child(4){
  flex-basis:52%;
}

.axScore {
  transform: rotate(-90deg);
}

.counter_1::after {
 content: '1';
  animation: counter 1s linear infinite alternate;
  counter-reset: count 0;
}
.counter_2::after {
 content: '1';
  animation: counter 2s linear infinite alternate;
  counter-reset: count 0;
}
.counter_3::after {
  content: '1';
  animation: counter 3s linear infinite alternate;
  counter-reset: count 0;
}
.counter_4::after {
 content: '1';
  animation: counter 4s linear infinite alternate;
  counter-reset: count 0;
}

@keyframes counter {
  0% {
    content: '0';
  }
}

.Swaper::after {
 content: '0';
}

@keyframes move {
  30% {transform:  rotateX(180deg);}
}

.CharacterSwaper{
  animation: move 1.5s infinite;
}
.CharacterSwaper::after {
  content: '?';
 }
.login-bg{
  background-color: #FED600 ;
}
.audit_list .MuiPaper-root {
  top: 60px !important;
  border: 1px solid;
  border-radius: 0;
}
.audit_list .MuiList-root {
  padding: 0;
}

.loader {
  border-radius: 50%;
  position: relative;
  transform:rotate(45deg);
  background: none;
}
.loader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border:10px solid #fff;
  animation: prixClipFix 2s infinite linear;
}

@keyframes prixClipFix {
    0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
    25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
    50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
    75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
    100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
}
